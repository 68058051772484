import React from 'react';
import Bootstrap from '../components/bootsrap';

const Uses = () => {
  return (
    <Bootstrap>
      Uses Page
    </Bootstrap>
  );
};

export default Uses;
